<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-form-textarea
          id="textarea"
          class="text-base"
          name="textarea"
          :value="value"
          :placeholder="placeholder"
          @change="onInput"
          rows="3"
          no-resize
        ></b-form-textarea>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";

export default {
  mixins: [DetailUpdateMixin],
  props: {
    value: {
      type: String,
      default: null,
    },
    field: {
      type: String
    },
    placeholder: {
      type: String
    }
  },
  methods: {
    onInput(value) {
      const data = {}
      data[this.field] = value;
      this.$store.dispatch(this.storeEditAction, data);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
